// type
import { microCmsAuthType } from "../types/microCmsAuthType";
import { microCmsContentsQueriesByCategoryType, microCmsContentsRequestQueriesType } from "../types/microCmsQueryTypes";

// カスタムモジュール
import { plt_microcms } from "../libs/plt_microcms";

/**
 * 
 * @param microCmsAuth 
 * @param microCmsQueries 
 * @returns 
 * microCMSの複数のエンドポイント(endpoint)のコンテンツを指定件数(limit)分取得して返却
 * useContentsList.hooks.tsと違い、Homeでは指定件数だけ取得してページネーションを利用しないのでこのフックではoffsetを指定しない。
 */
const useFetchContentsForHome = async(
  microCmsAuth: microCmsAuthType,
  microCmsQueries: microCmsContentsRequestQueriesType
  ) => {
  const client = plt_microcms(microCmsAuth.serviceDomain, microCmsAuth.apiKey);

  let responses;

  try {
    responses = await Promise.all(
      Object.entries(microCmsQueries).map(async ([key, endpoint]) => {
        const response = await client.get({
          endpoint: endpoint.endpointId,
          queries: {
            limit: endpoint.limit,
            fields: "id,title,publishedAt,eyecatch,category",
            orders: '-publishedAt',
          }
        });
        return {
          // [key]: { contents: response.contents, totalCount: response.totalCount, nextOffset: 0}
          [endpoint.endpointId]: { contents: response.contents, totalCount: response.totalCount, nextOffset: 0}
        };
      })
    );

    const formattedResponse = responses.reduce((acc, item) => {
      const key = Object.keys(item)[0];
      acc[key] = item[key];
      return acc;
    }, {});

    return { status: "200", responseBody: formattedResponse };

  } catch (e) {
    console.log(e);
    return { status: "400", responseBody: {}}
  }

}

export default useFetchContentsForHome;