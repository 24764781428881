import styles from "./ScrollIcon.module.scss";
import ScrollIconType from "./ScrollIconType";

const ScrollIcon = (props: ScrollIconType) => {
  const {id,color,commonClass} = props;


  return (
    <>
      <div className={`${styles.scrollIcon} ${color}`} id={id}>
        Scroll 
        <svg xmlns="http://www.w3.org/2000/svg" width="6.84" height="41.145" viewBox="0 0 6.84 41.145">
          <g transform="translate(6.5) rotate(90)">
            <path className={styles.scrollIcon_path} d="M5223.533,2747.526h40l-5.561-6" transform="translate(-5223.533 -2741.526)" fill="none" strokeWidth="1"/>
          </g>
        </svg>
      </div>
    </>
  )
}
export default ScrollIcon;