import SectionLayoutBasic from "../../../components/layouts/SectionLayoutBasic/SectionLayoutBasic";
import ArticleItemsBlock from "../../../components/organisms/ArticleItemsBlock/ArticleItemsBlock";

const HomeNews = (props: any) => {
  const {response} = props;
  return (
    <SectionLayoutBasic commonClass="white">
      <ArticleItemsBlock
        sectionTitleText={{
          text: "NEWS",
          tagType: "h2",
          commonClass: "section-title-text-class"
        }}
        flashTitleText={{
          text: "お知らせ",
          commonClass: "flash-title-text"
        }}
        direction={"column"}
        articleItems={{
          articleItems: response?.responseBody["news"]?.contents || [],
          endpoint: "news",
        }}
        linkButton={{
          text: "すべて見る",
          url: `/news/`,
          hasBorder: true
        }}
      />
    </SectionLayoutBasic>
  )
}

export default HomeNews;