import SectionLayoutBasic from "../../../components/layouts/SectionLayoutBasic/SectionLayoutBasic";
import ListContentsBlock from "../../../components/organisms/ListContentsBlock/ListContentsBlock";
import { homeOurServiceContext } from "./HomeOurServiceContext";

import styles from "./HomeOurService.module.scss";

const HomeOurService = (props: any) => {
  return (
    <SectionLayoutBasic commonClass={"gray"}>
      <div className={`${styles.oruService__inner} secInner__block`}>
        <ListContentsBlock
          id={"idListContentsBlock"}
          sectionComboTitleItem={{
            id: "id",
            sectionTitleText: {
              id: "id",
              text: "Our Service",
              tagType: "h2",
              commonClass: "section_combo_title_item",
            },
            flashTitleText: {
              id: "idFlashTitleText",
              text: "税務サービス / 経営支援サービス",
              commonClass: "flash_title_text_class"
            },
            direction: "row"
          }}
          listItems={homeOurServiceContext}
        />
      </div>
    </SectionLayoutBasic>
  )
}

export default HomeOurService;