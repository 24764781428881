export const homeOurServiceContext = [
  {
    imageItem: {
      imgPCPath: "/images/home/OurService/ourservice_img_01.png",
      webpPCPath: "/images/home/OurService/ourservice_img_01.webp",
      imgSPPath: "/images/home/OurService/ourservice_img_01_sp.png",
      webpSPPath: "/images/home/OurService/ourservice_img_01_sp.webp",
      altText: "スタンダードプラン",
      isLazy: true,
    },
    titleWithNumber: {
      text: "スタンダードプラン",
      number: "01",
      tagType: "h3",
      commonClass: "title-with-number"
    },
    paragraphText:{
      text: "<p>たなべ会計グループ（たなべ税理士事務所、株式会社アカウンティクス）が提供する標準プラン。</p><p>毎月の数値把握、四半期毎のモニタリングやシミュレーションによる検証などを通じて、常に安心して経営を行うために必要な情報や助言などを提供します。</p>"
    },
    linkButtonItem:{
      text: "詳しくはこちら",
      url: "/our-service/standard-plan/"
    }
  },
  {
    imageItem: {
      imgPCPath: "/images/home/OurService/ourservice_img_02.png",
      webpPCPath: "/images/home/OurService/ourservice_img_02.webp",
      imgSPPath: "/images/home/OurService/ourservice_img_02_sp.png",
      webpSPPath: "/images/home/OurService/ourservice_img_02_sp.webp",
      altText: "短期収集中税務改善プラン +MAS",
      isLazy: true,
    },
    titleWithNumber: {
      text: "短期収集中税務改善プラン +MAS",
      number: "02",
      tagType: "h3",
      commonClass: "title-with-number"
    },
    paragraphText:{
      text: "<p>1年程度の期間で、現状把握や状況改善に取り組む、短期集中プラン。</p><p>スタンダートプランとは異なり、最初にしっかりと計画を策定した後、毎月モニタリングとシミュレーションによる検証などを通じて、最短距離での業務拡大や、業績回復を狙うことを目的として専門的な助言などを提供します。</p>"
    },
    linkButtonItem:{
      text: "詳しくはこちら",
      url: "/our-service/plus-mas/"
    }
  },
  {
    imageItem: {
      imgPCPath: "/images/home/OurService/ourservice_img_03.png",
      imgSPPath: "/images/home/OurService/ourservice_img_03_sp.png",
      webpPCPath: "/images/home/OurService/ourservice_img_03.webp",
      webpSPPath: "/images/home/OurService/ourservice_img_03_sp.webp",
      altText: "セカンドオピニオン",
      isLazy: true,
    },
    titleWithNumber: {
      text: "セカンドオピニオン",
      number: "03",
      tagType: "h3",
      commonClass: "title-with-number"
    },
    paragraphText:{
      text: "<p>既存の税理士事務所を解約することなく、当グループが提供するサービスを提供するプラン。</p><p>スタンダードプランの標準サービスのうち、所定の税務サービスを省略することで割安な料金設定が可能です。</p>"
    },
    linkButtonItem:{
      text: "詳しくはこちら",
      url: "/our-service/second-opinion/"
    }
  }
];