import Link from "next/link";
import ArticleDate from "../../atoms/ArticleDate/ArticleDate";
import ArticleTitle from "../../atoms/ArticleTitle/ArticleTitle";
import FlashInformationType from "./FlashInformationType";

import styles from "./FlashInformation.module.scss";
import FlashTitle from "../../atoms/FlashTitle/FlashTitle";
import plt_formatDate from "../../../libs/plt_formatDate";

const FlashInformation = (props: FlashInformationType) => {
  const {id, flashTitle, articleDate, articleTitle, url} = props;

  return (
    <>
      {articleTitle.text ? (
      <article className={styles.flashInformation} id={id}>
        <div className={styles.flashInformation__flushTitle}>
          <FlashTitle text={flashTitle.text} />
        </div>
        <Link href={url} className={styles.flashInformation__link}>
          <div className={styles.flashInformation__articleDate}>
            <ArticleDate
              id={articleDate.id}
              // date={plt_formatDate(articleDate.date)}
              date={articleDate.date}
              commonClass={articleTitle.commonClass}
            />
          </div>
          <div className={styles.flashInformation__articleTitle}>
            <ArticleTitle
              id={articleTitle.id}
              text={articleTitle.text}
              commonClass={articleTitle.commonClass}
            />
          </div>
        </Link>
      </article>
      ): "loading"}
  </>
  )
}

export default FlashInformation;