import { link } from "fs";
import styles from "./HomeManifest.module.scss";
import LinkButton from "../../molecules/LinkButton/LinkButton";
import ManifestTitle from "../../atoms/ManifestTitle/ManifestTitle";
import ParagraphText from "../../atoms/ParagraphText/ParagraphText";
import HomeManifestType from "./HomeManifestType";
import ImageItem from "../../atoms/ImageItem/ImageItem";

const HomeManifest = (props: HomeManifestType) => {
  const {id, manifestTitle, paragraphText, imageItem, linkButton} = props;

  return (
    <section id={id} className={styles.homeManifest}>
      <div className={styles.homeManifest__inner}>
        <div className={styles.homeManifest__text}>
          <ManifestTitle
            id={manifestTitle.id}
            text={manifestTitle.text}
            commonClass={manifestTitle.commonClass}
          />
          <div className={styles.homeManifest__text__p}>
            <ParagraphText
              id={paragraphText.id}
              text={paragraphText.text}
              commonClass={paragraphText.commonClass}
            />
          </div>
          <div className={`${styles.linkButton} txtC_sp`}>
            <LinkButton
              id={linkButton.id}
              text={linkButton.text}
              url={linkButton.url}
              hasBorder={linkButton.hasBorder}
            />
          </div>
        </div>
        <ImageItem
          id={imageItem.id}
          imgPCPath={imageItem.imgPCPath}
          imgSPPath={imageItem.imgSPPath}
          webpPCPath={imageItem.webpPCPath}
          webpSPPath={imageItem.webpSPPath}
          widthPC={imageItem.widthPC}
          heightPC={imageItem.heightPC}
          widthSP={imageItem.widthSP}
          heightSP={imageItem.heightSP}
          altText={imageItem.altText}
          commonClass={imageItem.commonClass}
        />
      </div>
    </section>
  )
}

export default HomeManifest;