// カスタムモジュール
import { plt_microcms } from "../libs/plt_microcms";

// types
import { microCmsAuthType } from "../types/microCmsAuthType";
import { microCmsContentsRequestQueryType } from "../types/microCmsQueryTypes";

/**
 * microCMSコンテンツリスト
 * useHomeContents.hooks.tsと違い、このフックではoffsetを指定してページネーションで利用するためのもの。
 * @param microCmsAuth 
 * @param microCmsQueries
 * @returns 
 */
const useFetchVoicesForHome = async(
  microCmsAuth: microCmsAuthType,
  microCmsQueries : microCmsContentsRequestQueryType,
) => {
  const client = plt_microcms(microCmsAuth.serviceDomain, microCmsAuth.apiKey);

  try {
    const response = await client.get({
      endpoint: microCmsQueries.endpointId,
      queries: {
        limit: microCmsQueries.limit,
        fields: "id,publishedAt,title,eyecatch,name,area,plan,industry",
        orders: '-publishedAt',
      }
    });
    return {
      status: "200", 
      responseBody: {contents: response.contents, totalCount: response.totalCount}
    }
  } catch (e) {
    console.log(e);
    return { status: "400", responseBody: {}}
  }

}

export default useFetchVoicesForHome;