import plt_formatDate from "../../../libs/plt_formatDate";
import styles from "./ArticleDate.module.scss";
import ArticleDateType from "./ArticleDateType";

const ArticleDate = (props: ArticleDateType) => {
  const {id,date,commonClass} = props;
  return (
    <>
      <div className={`${styles.articleDate} articleDate`} id={id}>{plt_formatDate(date)}</div>
    </>
  )
}
export default ArticleDate;