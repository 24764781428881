import ImageItem from "../../atoms/ImageItem/ImageItem";
import ParagraphText from "../../atoms/ParagraphText/ParagraphText";
import TitleWithNumber from "../../atoms/TitleWithNumber/TitleWithNumber";
import LinkButton from "../../molecules/LinkButton/LinkButton";
import styles from "./ListContents.module.scss";
import ListContentsType from "./ListContentsType";

const ListContents = (props: ListContentsType) => {
  const {id, listItems} = props;
  return (
    <>
      {listItems && (
        <div className={styles.listItems}>
          {listItems?.map((item, index) => (
            <div key={index} className={styles.listItem}>
              <ImageItem
                id={item.imageItem.id}
                imgPCPath={item.imageItem.imgPCPath}
                imgSPPath={item.imageItem.imgSPPath}
                webpPCPath={item.imageItem.webpPCPath}
                webpSPPath={item.imageItem.webpSPPath}
                widthPC={item.imageItem.widthPC}
                heightPC={item.imageItem.heightPC}
                widthSP={item.imageItem.widthSP}
                heightSP={item.imageItem.heightSP}
                altText={item.imageItem.altText}
                commonClass={item.imageItem.commonClass}
                isLazy={item.imageItem.isLazy}
              />
              <div className={styles.titleWithNumber}>
                <TitleWithNumber
                  // id={item.titleWithNumber.id}
                  text={item.titleWithNumber.text}
                  number={item.titleWithNumber.number}
                  tagType={item.titleWithNumber.tagType}
                  commonClass={item.titleWithNumber.id}
                />
              </div>
              <ParagraphText
                id={item.paragraphText.id}
                text={item.paragraphText.text}
                commonClass={item.paragraphText.commonClass}
              />
              <div className={`${styles.linkButton} txtR`}>
                <LinkButton
                  id={item.linkButtonItem.id}
                  text={item.linkButtonItem.text}
                  url={item.linkButtonItem.url}
                  hasBorder={item.linkButtonItem.hasBorder}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default ListContents;