import styles from "./ManifestTitle.module.scss";
import ManifestTitleType from "./ManifestTitleType";

const ManifestTitle = (props: ManifestTitleType) => {
  const {id,text,commonClass} = props;
  return (
    <>
      <div className={`${styles.manifestTitle} ${commonClass}`} id={id} dangerouslySetInnerHTML={{ __html: text || '' }} />
    </>
  )
}
export default ManifestTitle;