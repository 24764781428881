import MainView from "../../../components/organisms/MainView/MainView"
import homeMainViewContext from "./HomeMainViewContext";
import HomeMainViewType from "./HomeMainViewType";

const HomeMainView = (props: HomeMainViewType) => {
  const {flashInformation} = props;

  return (
    <MainView
      id={homeMainViewContext.id}
      mainViewImage={homeMainViewContext.mainViewImage}
      mainViewCatch={homeMainViewContext.mainViewCatch}
      flashInformation={flashInformation}
      scrollIcon={homeMainViewContext.scrollIcon}
    />
  )
}

export default HomeMainView;