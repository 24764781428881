import LinkButton from "../../molecules/LinkButton/LinkButton";
import SectionComboTitle from "../../molecules/SectionComboTitle/SectionComboTitle";
import ArticleItemsWithImg from "../ArticleItemsWithImg/ArticleItemsWithImg";
import ArticleItemsWithImgBlockType from "./ArticleItemWithImgBlockType";
import styles from "./ArticleItemWithImgBlock.module.scss"

const ArticleItemsWithImgBlock = (props: ArticleItemsWithImgBlockType) => {

  const {id, sectionTitleText, flashTitleText, articleItems, endpoint, linkButton} = props;


  return (
    <div id={id} className={`${styles.articleBlog}`}>
      <div className="section__inner">
        <div className={styles.articleBlog__title}>
          <SectionComboTitle
            id={"section_combo_title"}
            sectionTitleText={{
              id: sectionTitleText.id,
              text: sectionTitleText.text,
              tagType: sectionTitleText.tagType,
              commonClass: sectionTitleText.commonClass
            }}
            flashTitleText={{
              id: flashTitleText.id,
              text: flashTitleText.text,
              commonClass: flashTitleText.commonClass
            }}
            direction={"row"}
          />
        </div>
        {articleItems ? (
          <ArticleItemsWithImg
            id="articleItemsWithImgId"
            articleItems={articleItems}
            endpoint={endpoint}
          />
          ) : ("Loading")}
        {articleItems ? (
          <>
            <div className={`${styles.articleBlog__linkButton} txtR_pc txtC_sp`}>
              <LinkButton 
                id={linkButton.id}
                text={linkButton.text}
                url={linkButton.url}
                hasBorder={linkButton.hasBorder}
              />
            </div>
          </>
        ) : ("")}
      </div>
    </div>
  )
}

export default ArticleItemsWithImgBlock;