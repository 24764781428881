// GET API以外は絶対に.env以外に置かない
// .envを使える(node環境でSSRするとき)ようになったら.envに移設してこのファイルは消すこと

const microCmsGetAuth = {
  // 本番
  serviceDomain: "tanabetax",
  apiKey: "pEQjDfFVVFvCiCym5pENVcefLnn6Y45ODsif"

  // 検証
  // serviceDomain: "microocmstest",
  // apiKey: "MiJkePa8RzF8xfPU3GFSImlO8m71L2CD7ug8"
}

export default microCmsGetAuth;