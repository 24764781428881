import META from "../../../constants/siteInfo/meta";

const homeMainViewContext = {
  id: "HomeMainViewContext",
  mainViewImage: {
    imgPCPath: "images/home/mv.png",
    webpPCPath: "images/home/mv.webp",
    imgSPPath: "images/home/mv_sp.png",
    webpSPPath: "images/home/mv_sp.webp",
    altText: META.siteTitle
  },
  mainViewCatch: {
    text: "経営者さまに寄り添い<br />一緒に考え、一緒に歩む。"
  },
  scrollIcon: {color: "base"}
}

export default homeMainViewContext;