import styles from "./ArticleAtribute.module.scss";
import ArticleAtributeType from "./ArticleAtributeType";

const ArticleAtribute = (props: ArticleAtributeType) => {
  const {id,text,commonClass} = props;
  return (
    <>
      <span className={`${styles.articleAtribute__text} articleAtribute__text`} id={id}>{text}</span>
    </>
  )
}
export default ArticleAtribute;