import HomeManifest from "../../../components/organisms/HomeManifest/HomeManifest";
import PAGE_INFO from "../../../constants/siteInfo/pageInfo";

const Manifest = () => {
  return (
    <HomeManifest
      id={"idHomeManifest"}
      manifestTitle={{
        id: "idManifestTitleId",
        text: "満足のひとつ上をいく<br />“感動”を与えるサービスを。",
        commonClass: "home-manifest"
      }}
      paragraphText={{
        id: "idParagraphTextId",
        text: "<p>私たちは、税理士に対する堅苦しい世間的イメージをできる限り取り除き、<br />常に福岡の経営者さまにとって、一番身近でかつ安心してご相談いただける存在でありたいと考えています。</p><p>税理士の基本的な役割は、税務に関する専門的なサービスを提供することですが、<br />私たちは、「お客様が常に最善の経営判断を行うことができるようサポートする」という役割を、<br />最も大切にしています。なぜなら、たとえ微力でも、お客様の繁栄に貢献することは、私たちが最もやりがいを感じることであるからです。<br />また、それは同時に、お客様に必要とされることにも繋がると考えているからです。</p><p>たなべ税理士事務所が掲げる理念「世の中に必要とされる存在であること」のもと、<br />お客様が求めるサービスについて、満足の一つ上をいく「感動」を与えられるサービスを提供していきます。</p>",
        commonClass: "paragraph-text"
      }}
      linkButton={{
        text: "たなべ税理士事務所について",
        url: PAGE_INFO["outline"].SLUG,
        hasBorder: true
      }}
      imageItem={{
        imgPCPath: "images/home/Manifest/manifest_img.png",
        imgSPPath: "images/home/Manifest/manifest_img_sp.png",
        altText: '満足のひとつ上をいく"感動”を与えるサービスを',
      }}
    />
  )
}

export default Manifest;