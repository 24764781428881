import MainViewCatch from "../../atoms/MainViewCatch/MainViewCatch";
import MainViewImage from "../../atoms/MainViewImage/MainViewImage";
import FlashInformation from "../../molecules/FlashInformation/FlashInformation";
import MainViewType from "./MainViewType";

import styles from "./MainView.module.scss";
import ScrollIcon from "../../atoms/ScrollIcon/ScrollIcon";

const MainView = (props: MainViewType) => {
  const {id, mainViewImage, mainViewCatch, flashInformation, scrollIcon} = props;

  return (
    <div id={id} className={styles.mainView}>
      <div className={styles.mainView__inner}>
        <MainViewImage
          id={mainViewImage.id}
          imgPCPath={mainViewImage.imgPCPath}
          imgSPPath={mainViewImage.imgSPPath}
          webpPCPath={mainViewImage.webpPCPath}
          webpSPPath={mainViewImage.webpSPPath}
          widthPC={mainViewImage.widthPC}
          heightPC={mainViewImage.heightPC}
          widthSP={mainViewImage.widthSP}
          heightSP={mainViewImage.heightSP}
          altText={mainViewImage.altText}
          commonClass={mainViewImage.commonClass}
        />
        <div className={styles.mainViewCatch}>
          <MainViewCatch
            id={mainViewCatch.id}
            text={mainViewCatch.text}
            commonClass={mainViewCatch.commonClass}
          />
        </div>
        <div className={styles.flashInformation}>
          {flashInformation && (
            <FlashInformation
              id={flashInformation.id}
              flashTitle={flashInformation.flashTitle}
              articleDate={flashInformation.articleDate}
              articleTitle={flashInformation.articleTitle}
              url={flashInformation.url || ""}
            />
          )}
        </div>
        <div className={styles.scrollIcon}>
          <ScrollIcon
            color={scrollIcon.color}
          />
        </div>
      </div>
    </div>
  )
}

export default MainView;