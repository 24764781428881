import SectionLayoutBasic from "../../../components/layouts/SectionLayoutBasic/SectionLayoutBasic";
import ArticleItemsWithImgBlock from "../../../components/organisms/ArticleItemWithImgBlock/ArticleItemWithImgBlock";

const HomeBlog = (props: any) => {
  const {response} = props;
  return (
    <>
      <SectionLayoutBasic commonClass="gray">
        <ArticleItemsWithImgBlock
          sectionTitleText={{
            id: "id",
            text: "BLOG",
            tagType: "h2",
            commonClass: "section-title-text-class"
          }}
          flashTitleText={{
            id: "id",
            text: "ブログ",
            commonClass: "flash-title-text"
          }}
          articleItems={response?.responseBody["blog"]?.contents}
          endpoint={"blog"}
          linkButton={{
            text: "すべて見る",
            url: `/blog/`,
            hasBorder: true
          }}
        />
      </SectionLayoutBasic>
    </>
  )
}
export default HomeBlog;