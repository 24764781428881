import SectionComboTitle from "../../molecules/SectionComboTitle/SectionComboTitle";
import ListContents from "../ListContents/ListContents";
import ListContentsBlockType from "./ListContentsBlockType";
import styles from "./ListContentsBlock.module.scss";

const ListContentsBlock = (props: ListContentsBlockType) => {
  const {id, sectionComboTitleItem, listItems} = props;

  return (
    <div id={id}>
      <div className={styles.sectionComboTitle}>
        <SectionComboTitle
          id={"idSectionComboTitle"}
          sectionTitleText={{
            id: sectionComboTitleItem.sectionTitleText.id,
            text: sectionComboTitleItem.sectionTitleText.text,
            tagType: sectionComboTitleItem.sectionTitleText.tagType,
            commonClass: sectionComboTitleItem.sectionTitleText.commonClass
          }}
          flashTitleText={{
            id: sectionComboTitleItem.flashTitleText.id,
            text: sectionComboTitleItem.flashTitleText.text,
            commonClass: sectionComboTitleItem.flashTitleText.commonClass
          }}
          direction={sectionComboTitleItem.direction}
        />
      </div>
      <ListContents
        listItems={listItems}
      />
    </div>
  )
}
export default ListContentsBlock;