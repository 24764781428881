import ArticleDate from "../../atoms/ArticleDate/ArticleDate";
import ArticleTitle from "../../atoms/ArticleTitle/ArticleTitle";
import IconArrowSvg from "../../atoms/IconArrowSvg/IconArrowSvg";
import ArticleItemType from "./ArticleItemType"

import styles from "./ArticleItem.module.scss";
import ArticleLabel from "../../atoms/ArticleLabel/ArticleLabel";

const ArticleItem = (props: ArticleItemType) => {
  const {id, articleTitle, articleDate, articleLabel, iconArrow,url,commonClass} = props;
  return (
    <div className={`${styles.articleItem}`} id={id}>
      <div className={styles.articleItem__inner}>
        <ArticleDate
          id={articleDate.id}
          date={articleDate.date}
          commonClass={articleDate.commonClass}
        />
        {articleLabel && (
          <ArticleLabel
            id={articleLabel.id}
            text={articleLabel.text}
            url={articleLabel.url}
            commonClass={articleLabel.commonClass}
          />
        )}
        <a href={url}>
          <ArticleTitle
            id={articleTitle.id}
            text={articleTitle.text}
            commonClass={articleTitle.commonClass}
          />
        </a>
        <IconArrowSvg
          id={iconArrow.id}
          width={iconArrow.width}
          height={iconArrow.height}
          commonClass={iconArrow.commonClass}
        />
      </div>
    </div>
  )
}

export default ArticleItem;