import { ReactNode } from "react"

import styles from "./SectionLayoutBasic.module.scss";
import SectionLayoutBasicType from "./SectionLayoutBasicType";

const SectionLayoutBasic = ({ children, commonClass, id }: SectionLayoutBasicType) => {
  return (
    <section id={id} className={commonClass}>
      {children}
    </section>
  );
};

export default SectionLayoutBasic;