import FlashTitle from "../../atoms/FlashTitle/FlashTitle";
import LinkButton from "../../molecules/LinkButton/LinkButton";
import SectionTitle from "../../atoms/SectionTitle/SectionTitle";
import ArticleItems from "../ArticleItems/ArticleItems";
import ArticleItemsBlock from "./ArticleItemsBlockType";

import styles from "./ArticleItemsBlock.module.scss";

const ArticleItemsBlock = (props: ArticleItemsBlock) => {
  const {id, sectionTitleText, flashTitleText, direction, articleItems, linkButton } = props;
  
  return (
    <div className={`${styles.articleNews}`}>
      <div className={`${direction} section__inner`}>
        <div className={styles.articleNews__inner}>
          <div className={`${styles.direction}`}>
            <SectionTitle 
              id={id}
              text={sectionTitleText.text}
              tagType={sectionTitleText.tagType}
              commonClass={sectionTitleText.commonClass}
            />
            <FlashTitle
              id={flashTitleText.id}
              text={flashTitleText.text}
              commonClass={flashTitleText.commonClass}
            />
          </div>
          <div className={styles.articleNews__items}>
            {articleItems ? (
              <ArticleItems
                articleItems={articleItems.articleItems}
                endpoint={articleItems.endpoint}
              />
            ) : (
              <p>現在準備中です。</p>
            )}
          </div>
        </div>
        <div className={`${styles.articleNews__linkButton} txtC_sp`}>
          <LinkButton
            id={linkButton.id}
            text={linkButton.text}
            url={linkButton.url}
            hasBorder={linkButton.hasBorder}
          />
        </div>
      </div>
    </div>
  )
}

export default ArticleItemsBlock;