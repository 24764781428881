import ArticleItemWithImgType from "./ArticleItemWithImgType"
import ImageItem from "../../atoms/ImageItem/ImageItem";
import ArticleTitle from "../../atoms/ArticleTitle/ArticleTitle";
import IconNewSvg from "../../atoms/IconNew/IconNew";

import styles from "./ArticleItemWithImg.module.scss";
import ArticleDate from "../../atoms/ArticleDate/ArticleDate";

const ArticleItemWithImg = (props: ArticleItemWithImgType) => {
  const {id, imageItem,articleDate,articleTitle,iconNew,url,commonClass} = props;
  return (
    <div id={id} className={`${styles.ArticleItemWithImg}`}>
      <a href={url} className={styles.ArticleItemWithImg__inner}>
        <div className={styles.ArticleItemWithImg__img}>
          <ImageItem 
            id={imageItem.id}
            imgPCPath={imageItem.imgPCPath}
            imgSPPath={imageItem.imgSPPath}
            webpPCPath={imageItem.webpPCPath}
            webpSPPath={imageItem.webpSPPath}
            widthPC={imageItem.widthPC}
            heightPC={imageItem.heightPC}
            widthSP={imageItem.widthSP}
            heightSP={imageItem.heightSP}
            altText={imageItem.altText}
            commonClass={imageItem.commonClass}
            />
          {/* <div className={styles.iconNew}>
            <IconNewSvg
              id={iconNew.id}
              text={iconNew.text}
            />
          </div> */}
        </div>
        <div className={styles.ArticleItemWithImg__titleWrapper}>
          <div className={styles.articleDate}>
            <ArticleDate 
              id={articleDate.id}
              date={articleDate.date}
              commonClass={articleDate.commonClass}
            />
          </div>
          <div className={styles.articleTitle}>
            <ArticleTitle 
              id={articleTitle.id}
              text={articleTitle.text}
              commonClass={articleTitle.commonClass}
            />
          </div>
        </div>
      </a>
    </div>
  )
}

export default ArticleItemWithImg