import MainViewImageType from "./MainViewImageType";

const MainViewImage = (props: MainViewImageType) => {
  const {id,imgPCPath,imgSPPath,webpPCPath,webpSPPath,widthPC,heightPC,widthSP,heightSP,altText,commonClass} = props;
  const imgElement = () => {
    if(webpPCPath) {
      if(webpSPPath) {
        return (
          <picture>
            <source srcSet={webpSPPath} media="(max-width:768px)" width={widthSP} height={heightSP} />
            <img src={webpPCPath} alt={altText} width={widthPC} height={heightPC} id={id} fetchPriority={"high"} />
          </picture>
        )
      }else {
        return (
          <img src={webpPCPath} alt={altText} width={widthPC} height={heightPC} id={id} fetchPriority={"high"} />
        )
      }
    }
    else if(imgPCPath) {
      if(imgSPPath) {
        return (
          <picture>
            <source srcSet={imgSPPath} media="(max-width:768px)" width={widthSP} height={heightSP} />
            <img src={imgPCPath} alt={altText} width={widthPC} height={heightPC} id={id} fetchPriority={"high"} />
          </picture>
        )
      }else {
        return(
          <img src={imgPCPath} alt={altText} width={widthPC} height={heightPC} id={id} fetchPriority={"high"} />
        )
      }
    }
  }
  return (
    <>
      {imgElement()}
    </>
  )
}
export default MainViewImage;