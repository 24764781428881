import ENDPOINTS from "../../../constants/apis/endpoints";
import ArticleItem from "../../molecules/ArticleItem/ArticleItem";
import styles from "./ArticleItems.module.scss";
import ArticleItemsType from "./ArticleItemsType";

const ArticleItems = (props: ArticleItemsType) => {
  const {id, articleItems, endpoint} = props;
  return (
    <ul id={id}>
      {articleItems.map((article, key) => (
        <li key={key} className={styles.articleItem}>
          <ArticleItem
            id={article.id}
            articleTitle={{
              text: article.title,
              commonClass: "article_title_class",
            }}
            articleDate={{
              date: article.publishedAt,
              commonClass: "article_date_class",
            }}
            articleLabel={{
              text: article.category && article.category[0] ? article.category[0] : "",
              url: article.category && article.category[0] ? article.category[0] : "",
              commonClass: "article_date_class"
            }}
            iconArrow={{
              width: 24,
              height: 5
            }}
            url={`/${article.category && article.category[0] ? mapCategorySlug(article.category[0]) : ""}/post/?id=${article.id}`}
          />
        </li>
      ))}
    </ul>
  )
}


const mapCategorySlug = (category: string) => {
  switch (category) {
    case 'セミナー情報':
      return 'seminar';
    case ENDPOINTS["news"].CATEGORIES["news"].CATEGORY_NAME:
      return 'news';
    // 他にも必要な場合は適宜追加
    default:
      return category.toLowerCase(); // カテゴリ名を小文字に変換するなど
  }
}

export default ArticleItems;