import Link from "next/link";
import ArticleLabelType from "./ArticleLabelType";

import styles from "./ArticleLabel.module.scss";

const ArticleLabel = (props: ArticleLabelType) => {
  const {id, text, url, commonClass} = props;
  const customUrl = url === "お知らせ"
  ? "news"
  : url && url === "セミナー情報"
    ? "seminar"
    : "blog";

  const linkHref =
    customUrl === "blog"
      ? `/blog/`
      : `/${customUrl}/`;

  return (
    <div className={`${styles.articleLabel} ${commonClass}`} id={id}>
      <Link href={linkHref}>{text}</Link>
    </div>
  )
}

export default ArticleLabel;