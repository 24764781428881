import styles from "./MainViewCatch.module.scss";
import MainViewCatchType from "./MainViewCatchType";

const MainViewCatch = (props: MainViewCatchType) => {
  const {id,text,commonClass} = props;
  return (
    <>
      <div className={`${styles.mainViewCatch}`} id={id} dangerouslySetInnerHTML={{ __html: text || '' }} />
    </>
  )
}
export default MainViewCatch;