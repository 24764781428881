import styles from "./TitleWithNumber.module.scss";
import TitleWithNumberType from "./TitleWithNumberType";

const TitleWithNumber = (props: TitleWithNumberType) => {
  const {id,text,number,tagType:CustomTag="h2",commonClass} = props;
  return (
    <>
      <div className={`${styles.titleWithNumber} titleWithNumber`} id={id}>
        <span className={`${styles.titleWithNumber__num} titleWithNumber__num`}>{number}</span>
        <div className={`${styles.titleWithNumber__text}`}>
          {text}
        </div>
      </div>
    </>
  )
}
export default TitleWithNumber;