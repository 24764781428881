import { ENDPOINTS_TYPE } from "../../types/microCMSTypes";

const ENDPOINTS: ENDPOINTS_TYPE = {
  "news": {
    "ID": "news",
    "NAME": "お知らせ",
    "CATEGORIES": {
      "news": {
        "SLUG": "news",
        "CATEGORY_NAME": "お知らせ"
      },
      "seminar": {
        "SLUG": "seminar",
        "CATEGORY_NAME": "セミナー情報"
      }
    },
    "POSTS_PER_PAGE": 10,
  },
  "blog": {
    "ID": "blog",
    "NAME": "ブログ",
    "CATEGORIES": {},
    "POSTS_PER_PAGE": 10,
  },
  "voice": {
    "ID": "voice",
    "NAME": "お客様の声",
    "CATEGORIES": {},
    "POSTS_PER_PAGE": 5
  }
}

export type TargetEndpointId = {
  targetEndpointId: string;
}

export type TargetCategoryNameType = {
  targetEndpointId: string;
  targetCategoryName?: string;
}

export default ENDPOINTS;