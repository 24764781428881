import LinkButton from "../../molecules/LinkButton/LinkButton";
import SectionComboTitle from "../../molecules/SectionComboTitle/SectionComboTitle";
import ArticleVoiceItems from "../ArticleVoiceItems/ArticleVoiceItems";
import ArticleVoiceItemsBlockType from "./ArticleVoiceItemsBlockType";
import styles from "./ArticleVoiceItemsBlock.module.scss"

const ArticleVoiceItemsBlock = (props: ArticleVoiceItemsBlockType) => {
  const {id, sectionTitleText, flashTitleText, articleItems, linkButton} = props;

  return (
    <div id={id} className="articleVoice">
      <div className={styles.articleVoice__title}>
        <SectionComboTitle 
          id={"section_combo_title"}
          sectionTitleText={{
            id: sectionTitleText.id,
            text: sectionTitleText.text,
            tagType: sectionTitleText.tagType,
            commonClass: sectionTitleText.commonClass
          }}
          flashTitleText={{
            id: flashTitleText.id,
            text: flashTitleText.text,
            commonClass: flashTitleText.commonClass
          }}
          direction={"row"}
        />
      </div>
      {articleItems.articleItems ? (
        <ArticleVoiceItems
          id={articleItems.id}
          articleItems={articleItems.articleItems}
          endpoint={articleItems.endpoint}
        />
        ): ("Loading")
      }
      <div className={`${styles.articleVoice__button} txtR_pc txtC_sp`}>
        <LinkButton
          id={linkButton.id}
          text={linkButton.text}
          url={linkButton.url}
          hasBorder={linkButton.hasBorder}
        />
      </div>
    </div>
  )
}

export default ArticleVoiceItemsBlock;