import styles from "./ArticleTitle.module.scss";
import ArticleTitleType from "./ArticleTitleType";

const ArticleTitle = (props: ArticleTitleType) => {
  const {id,text,commonClass} = props;
  return (
    <>
      <h2 className={`${styles.articleTitle}`} id={id}>{text}</h2>
    </>
  )
}
export default ArticleTitle;